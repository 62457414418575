import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer } from '../components/Blocks'
import { HeroVideo, CoreValues } from '../components/Sections'
import { Container } from 'reactstrap'
import styled from 'styled-components'

const Block = styled.div`
  height: 55vh;
  @media screen and (min-width: 1800px) {
    height: 80vh;
  }
  @media screen and (min-width: 2600px) {
    height: 100vh;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Organization Chart" keywords={[`gatsby`, `application`, `react`]} />
    <HeroVideo id="main-hero" x={50} y={50} height={null} src="app/Video/anniversary.mp4" posterSrc={getAssetUrl('app/video-poster.png')} loop={true} autoplay muted>
    {/* <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/"  posterSrc={getAssetUrl('app/ASEAN_Banner.jpg')} loop={true} autoplay muted> */}
      <Block />
    </HeroVideo>
    <BlockContainer padding={0}>
      <Container fluid className="p-0">
        <img className="img-fluid" src={getAssetUrl('app/OrgChart.jpg')} alt="ORGANIZATION CHART" />
      </Container>
    </BlockContainer>
    <CoreValues />
    {/* <BlockContainer padding={5} dark id="awards">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
                <h2 className="pb-4 text-dark">Awards</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col sm={{order: 2}} md={{ size: 3, order: 1}} className="align-self-center text-md-right text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          <Col sm={{ order: 1 }} md={{ size: 6, order: 2}} className="my-3 d-none d-md-block"> 
              <img className="img-fluid d-block pt-5" alt="SIDC BENEFITS" src={getAssetUrl('app/Awards.png')} />
          </Col>
          <Col sm={{order: 3}} md={{ size: 3, order: 3}} className="align-self-center text-md-left text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          </Row>
      </Container>
    </BlockContainer> */}
  </Layout>
)

export default IndexPage
